// extracted by mini-css-extract-plugin
export var alertRed = "Form-module--alert-red--Txd+h";
export var bgBarGrey = "Form-module--bg-bar-grey--8kvuS";
export var bgBeige = "Form-module--bg-beige--qMcin";
export var bgCopper = "Form-module--bg-copper--uWYr-";
export var bgDaffodil = "Form-module--bg-daffodil--eYX93";
export var bgGreyText = "Form-module--bg-grey-text--bOyLm";
export var bgMing = "Form-module--bg-ming--yTtsO";
export var bgMint = "Form-module--bg-mint--cwB5q";
export var bgRed = "Form-module--bg-red--xjqLS";
export var bgRedTrans = "Form-module--bg-red-trans--OnwdV";
export var bgSand = "Form-module--bg-sand--X1k3X";
export var bgSoftSun = "Form-module--bg-softSun--qQZn2";
export var bgTeal = "Form-module--bg-teal--LDxSJ";
export var bgWhite = "Form-module--bg-white--f5Bdo";
export var bgWomenSharp = "Form-module--bg-women-sharp--Hw8f0";
export var blackText = "Form-module--black-text--0ClbR";
export var compact = "Form-module--compact--b2U29";
export var copper = "Form-module--copper--KRY-u";
export var corners = "Form-module--corners--iuRMI";
export var countryCode = "Form-module--countryCode--+NfXS";
export var cursorNormal = "Form-module--cursor-normal--mg3E5";
export var cursorPointer = "Form-module--cursor-pointer--d47Hn";
export var dUnset = "Form-module--d-unset--n6vmq";
export var darkGrey = "Form-module--dark-grey--bZh12";
export var dash = "Form-module--dash----L9v";
export var error = "Form-module--error--n8Srq";
export var filled = "Form-module--filled--T5slS";
export var flex = "Form-module--flex--G8Ux+";
export var flex1 = "Form-module--flex-1--L1v2k";
export var fontSize12 = "Form-module--font-size-12--XokZI";
export var fontSize20 = "Form-module--font-size-20--L5xyG";
export var formSelect = "Form-module--formSelect--UV8E2";
export var greyPlaceholder = "Form-module--grey-placeholder--GtrjG";
export var greyText = "Form-module--grey-text--STzVk";
export var h1sizing = "Form-module--h1sizing--ytqaT";
export var h2sizing = "Form-module--h2sizing--sf1lg";
export var hidden = "Form-module--hidden--VQHvM";
export var imageAni = "Form-module--imageAni--HL9bT";
export var imageZoom = "Form-module--imageZoom--TIt-j";
export var imageZoomGatsby = "Form-module--imageZoomGatsby--Pe3cF";
export var infoRow = "Form-module--infoRow--rjr3r";
export var italic = "Form-module--italic--LexBM";
export var lightGrey = "Form-module--light-grey--MhCcd";
export var logo = "Form-module--logo--3uujW";
export var lora = "Form-module--lora--7Quw3";
export var loraBold = "Form-module--lora-Bold--zfxYC";
export var loraBoldItalic = "Form-module--lora-BoldItalic--x-QIc";
export var loraMedium = "Form-module--lora-Medium--r-IDo";
export var loraSemiBold = "Form-module--lora-SemiBold--Gl9XG";
export var main = "Form-module--main--ZxpgF";
export var ming = "Form-module--ming--tKYAQ";
export var mingHover2 = "Form-module--ming-hover-2--MAJcs";
export var modalOpen = "Form-module--modal-open--YCeIa";
export var noUnderline = "Form-module--no-underline--GkYU5";
export var openSans = "Form-module--openSans--KcmFT";
export var openSansBold = "Form-module--openSans-Bold--BLWNE";
export var openSansSemiBold = "Form-module--openSans-SemiBold--5UWh9";
export var phone = "Form-module--phone--U2nn0";
export var quotation = "Form-module--quotation--Vi1Wc";
export var quotationform = "Form-module--quotationform--ZUBQW";
export var round = "Form-module--round--s-tbH";
export var roundCorners = "Form-module--round-corners--2QcAI";
export var softCorners = "Form-module--soft-corners--IzaEq";
export var softShadows = "Form-module--soft-shadows--abTcx";
export var softerCorners = "Form-module--softer-corners--vBT5o";
export var softyCorners = "Form-module--softy-corners--xpmKZ";
export var tableShadow = "Form-module--table-shadow--zwbDQ";
export var teal = "Form-module--teal--GPyCH";
export var topNavShadow = "Form-module--top-nav-shadow--3Ji1P";
export var uppercase = "Form-module--uppercase--7l4IC";
export var valid = "Form-module--valid--yc6SK";
export var wFull = "Form-module--w-full--+DOIZ";
export var white = "Form-module--white--eYkxU";
export var womenRed = "Form-module--women-red--buxhx";
export var womenSharp = "Form-module--women-sharp--3UQHC";