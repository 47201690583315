import React from "react"

import { replaceByName, numberWithCommas } from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"

const Covid = props => {
  const {
    // title,
    covidCaseDaily,
    vaccinationNumber,
    totalCaseNumber,
    deathNumber,
    translation,
    content,
  } = props.article
  const options = { weekday: "long" }
  const localeMap = {
    en: "en-US",
    fr: "fr-FR",
    "zh-Hant": "zh-HK",
  }

  let last7DaysDataArr = []

  if (covidCaseDaily.length < 2) return <></>

  covidCaseDaily.map(item => {
    item.weekday = new Date(item.date).toLocaleDateString(
      localeMap[props.lang] ?? "en-US",
      options
    )
    last7DaysDataArr.push(
      `${item.weekday}: ${item.total} ${translation.casesLabel} (${item.local} ${translation.localLabel})`
    )
    return item
  })

  const latest = covidCaseDaily.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )

  const replacementList = {
    latestWeekday: latest[0].weekday,
    latestWeekdayCaseNumber: numberWithCommas(latest[0].total),
    previousWeekdayCaseNumber: numberWithCommas(latest[1].total),
    previousWeekday: latest[1].weekday,
    latestWeekdayLocalCaseNumber: numberWithCommas(latest[0].local),
    latestWeekdayImportedCaseNumber: numberWithCommas(
      latest[0].total - latest[0].local
    ),
    totalCaseNumber: numberWithCommas(totalCaseNumber),
    totalDeathNumber: numberWithCommas(deathNumber),
    totalVaccinationNumber: numberWithCommas(vaccinationNumber),
    last7DaysData: last7DaysDataArr.join("\n\n"),
  }

  let updatedContent = content

  for (const key in replacementList) {
    updatedContent = replaceByName(
      updatedContent,
      `{{${key}}}`,
      replacementList[key]
    )
  }

  return (
    <>
      <CustomMarkdown html={updatedContent} toSanitize={false}></CustomMarkdown>
    </>
  )
}

export default Covid
