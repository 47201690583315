import * as style from "./Form.module.scss"

import React from "react"
import { getPhoneCodes } from "../../plugins/common"

const QuotationForm = props => {
  const {
    form,
    isHalf,
    isCenter,
    isVertical,
    errors,
    labels,
    extraClass,
    allWhite,
  } = props

  const {
    emailLabel,
    phoneLabel,
    firstNameLabel,
    familyNameLabel,
    emailPlaceHolder,
    phonePlaceHolder,
    firstNamePlaceHolder,
    familyNamePlaceHolder,
  } = labels

  const phoneCodeList = getPhoneCodes()

  const getLabelWidth = () =>
    typeof isHalf === "undefined"
      ? "col-lg-2"
      : isVertical && isHalf
      ? "col-lg-12 col-12"
      : "col-lg-3"
  const getInputWidth = () =>
    typeof isHalf === "undefined"
      ? "col-lg-5"
      : isVertical && isHalf
      ? "col-lg-12 col-12"
      : "col-lg-8"

  return (
    <div
      className={`${style.quotationform} container ${isHalf ? "" : "px-0"} ${
        isCenter ? "col-lg-6" : ""
      } ${extraClass ? style[extraClass] : ""}`}
    >
      <div className={`${isHalf ? " " : "d-lg-flex "}`}>
        <div
          className={`row ${style.infoRow} ${
            isHalf ? "" : "col-lg-6  col-12 mx-0 justify-content-start"
          }`}
        >
          <div
            className={`${getLabelWidth()} ${
              allWhite
                ? "white"
                : errors.firstName
                ? "alert-red"
                : form.firstName.length
                ? ""
                : "ming"
            } col-12 ming px-0`}
          >
            {firstNameLabel}*
          </div>
          <div className={`${getInputWidth()} col-12 px-0`}>
            <input
              className={`${style.quotation} ${
                errors.firstName
                  ? style.error
                  : form.firstName.length
                  ? style.valid
                  : ""
              }`}
              type="text"
              placeholder={firstNamePlaceHolder}
              defaultValue={form.firstName}
              onChange={e => (form.firstName = e.target.value)}
            />
          </div>
        </div>
        <div
          className={`row ${style.infoRow} ${
            isHalf ? "" : "col-lg-6  col-12 mx-0 justify-content-start"
          }`}
        >
          <div
            className={`${getLabelWidth()} col-12 ming px-0 ${
              allWhite
                ? "white"
                : errors.familyName
                ? "alert-red"
                : form.familyName.length
                ? ""
                : "ming"
            }`}
          >
            {familyNameLabel}*
          </div>
          <div className={`${getInputWidth()} col-12 px-0`}>
            <input
              className={`${style.quotation} ${
                errors.familyName
                  ? style.error
                  : form.familyName.length
                  ? style.valid
                  : ""
              }`}
              type="text"
              placeholder={familyNamePlaceHolder}
              defaultValue={form.familyName}
              onChange={e => (form.familyName = e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={`${isHalf ? "mb-3 " : "d-lg-flex "}`}>
        <div
          className={`row ${style.infoRow} ${
            isHalf ? "" : "col-lg-6 col-12 mx-0 justify-content-start"
          }`}
        >
          <div
            className={`${getLabelWidth()} col-12 ming px-0 ${
              allWhite
                ? "white"
                : errors.phone
                ? "alert-red"
                : form.phone.length
                ? ""
                : "ming"
            }`}
          >
            {phoneLabel}*
          </div>
          <div className={`${getInputWidth()} col-12 row px-0 mx-0`}>
            <div className={`col-2 px-0 ${style.countryCode}`}>
              <select
                className={`${style.formSelect} ${
                  errors.phone
                    ? style.error
                    : form.phone.length
                    ? style.filled
                    : ""
                }`}
                defaultValue={form.countryCode}
                onChange={e => (form.countryCode = e.target.value)}
              >
                {phoneCodeList.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className={`col-10 pe-0 `}>
              <input
                className={`${style.quotation} ${style.phone} ${
                  errors.phone
                    ? style.error
                    : form.phone.length
                    ? style.valid
                    : ""
                }`}
                type="number"
                placeholder={phonePlaceHolder}
                defaultValue={form.phone}
                onChange={e => (form.phone = e.target.value)}
              />
            </div>
          </div>
        </div>
        <div
          className={`row ${style.infoRow} ${
            isHalf ? "" : "col-lg-6  col-12 mx-0 justify-content-start"
          }`}
        >
          <div
            className={`${getLabelWidth()} col-12 ming px-0 ${
              allWhite
                ? "white"
                : errors.email
                ? "alert-red"
                : form.email.length
                ? ""
                : "ming"
            }`}
          >
            {emailLabel}*
          </div>
          <div className={`${getInputWidth()} col-12 px-0`}>
            <input
              className={`${style.quotation} ${
                errors.email
                  ? style.error
                  : form.email.length
                  ? style.valid
                  : ""
              }`}
              type="text"
              placeholder={emailPlaceHolder}
              defaultValue={form.email}
              onChange={e => (form.email = e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default QuotationForm
