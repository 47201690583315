import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { isUrlAbsolute } from "../plugins/url"

const ImageCta = ({ cta }) => {
  const { image, url } = cta

  return (
    <div className={`imageCta text-center`}>
      <a
        href={url}
        target={isUrlAbsolute(url) ? "_blank" : ""}
        rel="noreferrer"
      >
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          image={getImage(image?.localFile)}
          alt={image.alternativeText}
        />
      </a>
    </div>
  )
}

export default ImageCta
