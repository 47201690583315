import "./article.scss"

import React, { useState, useEffect } from "react"

import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import gql from "graphql-tag"
import removeMd from "remove-markdown"

import Covid from "../components/Covid"
import CustomIcon from "../components/CustomIcon"
import Layout from "../components/Layout"
import Featured from "../components/resources/Featured"
import SocialIcon from "../components/SocialIcon"
import {
  formatLongDate,
  getAllLocales,
  // getBackgroundImage,
  getCurrentLanguagePage,
  getExternalImg,
  reformatCta,
} from "../plugins/common"
import { CustomMarkdown } from "../plugins/sanitizehtml"
import Button from "../components/Button"
import { client } from "../plugins/apollo"
import { slugify } from "../plugins/url"
import FaqInPage from "../components/FaqInPage"
import ContactForm from "../components/ContactForm"
import ImageCta from "../components/ImageCta"
const ArticleTemplate = ({ pageContext, location }) => {
  const token = pageContext.general.aleaToken
  const { form, footerForm, article, labels } = pageContext
  const enableFootnote = article.footnote?.content.length > 2
  const { factCheckedLabel, ContentsLabel, lastUpdateLabel, moreArticleLabel } =
    labels.find(l => l.locale === article.locale)?.browse
  const image = getImage(
    article.image?.localFile?.childImageSharp?.gatsbyImageData
  )
  const [metaData, setMetaData] = useState(article?.metaData)
  const leftSun = "../images/articles/left-sun.webp"
  const leftLine = "../images/articles/left-line.webp"
  const rightCopper = "../images/articles/right-copper.webp"
  const rightLine = "../images/articles/right-line.webp"

  const faqCompLabel = "layout.faq"
  const contactFormLabel = "layout.contact-form"

  const general = pageContext.general.allStrapiGeneral.nodes[0]
  const sideImageCta = general?.imageCta
  const simplifyForm = general?.simplifyForm
  const enableSideImageCta = sideImageCta?.enable
  const enableSideForm = simplifyForm.enable
  const enableFooterQuote = general.articleQuote?.enableFooter
  const footerQuote = general.articleQuote
  const footerFormLabels = {
    ...footerQuote,
    ...footerForm.freeQuote,
  }
  // console.log("%c general", "color:aqua", footerFormLabels)

  // console.log("article: ", article)
  const headerCta = reformatCta(article.headerCta, "quote")

  const contactFormLabels = {
    ...form[0].form,
    ...simplifyForm,
    submitLabel: form[0].submitLabel,
  }

  const updateTimeStr = formatLongDate(article.updated_at, article.locale)

  const [moreArticles, setMoreArticles] = useState({
    title: moreArticleLabel,
    articles: [],
  })
  const [moreArticlesError, setMoreArticlesError] = useState(true)
  useEffect(() => {
    // console.log("client =", client())
    client(token)
      .query({
        query: gql`
          query ($slug: String!, $categoryId: [Int], $locale: String!) {
            articles(
              limit: 3
              locale: $locale
              where: {
                _slug_nin: $slug
                article_categories: { id_in: $categoryId }
              }
              sort: "updated_at"
            ) {
              id
              locale
              updated_at
              article_categories {
                id
                name
              }
              title
              image {
                url
                alternativeText
              }
              summary
              slug
            }
          }
        `,
        variables: {
          slug: article.slug,
          categoryId: article.article_categories.map(c => c.id),
          locale: article.locale,
        },
      })
      .then(result => {
        setMoreArticlesError(false)
        const articles = result.data.articles.map(d => {
          return {
            img: process.env.GATSBY_API_URL + d.image?.url ?? "",
            category: d.article_categories.map(c => c.name).join(" / "),
            title: d.title,
            slug: d.slug,
          }
        })
        setMoreArticles({ title: moreArticleLabel, articles: articles })
      })
      .catch(err => {
        setMoreArticlesError(true)
      })
  }, [article, token, moreArticleLabel])

  const languageOptions = pageContext.general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)

  return (
    <div className="article">
      <Layout
        props={pageContext.general}
        meta={metaData}
        localizations={getAllLocales(article.localizations, article.locale)}
        location={location}
        lang={lang}
        ogImage={article.image?.localFile?.publicURL}
        noindex={article.noindex}
        token={token}
        footerForm={enableFooterQuote ? footerFormLabels : null}
      >
        <div className="overflow-hidden">
          <div className="shapesRow position-absolute">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className=" leftSun position-absolute"
              src={leftSun}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className=" leftLine position-absolute"
              src={leftLine}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className=" rightCopper position-absolute"
              src={rightCopper}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className=" rightLine position-absolute"
              src={rightLine}
              alt="alea"
            />
          </div>

          <div className="container-fluid noPadLeft background">
            <div className="m-auto row ming header">
              <div className="col-lg-6 col-12 noPadLeft header-left">
                <div className="row infoRow text-lg-start text-center">
                  <div className="col-lg-5 col-12 teal">
                    {article.article_categories.map(c => c.name).join(" / ")}
                  </div>
                  <div className="col-lg-7 d-lg-block d-none ">
                    {article.factChecked && (
                      <div className="fced d-flex openSans-Bold">
                        <div className="fcIcon">
                          <CustomIcon icon="factChecked" />
                        </div>
                        {factCheckedLabel}
                      </div>
                    )}
                  </div>
                </div>
                <h1 className="title text-lg-start text-center">
                  {article.title}
                </h1>
                {article.summary && (
                  <div className="summary d-none d-lg-block">
                    {article.summary}
                  </div>
                )}
                {headerCta && (
                  <div className="btn-wrapper text-lg-start text-center">
                    <Button
                      buttonId={`hero_quote_cta`}
                      lang={lang}
                      type={headerCta.template}
                      text={headerCta.buttonLabel}
                      url={headerCta.url}
                    />
                  </div>
                )}
              </div>
              <div className="row mobileInfoRow d-lg-none d-block d-flex">
                <div className="lastUpdate teal lora-Bold col-7">
                  {` ${lastUpdateLabel}:`}
                  <br />
                  {`${updateTimeStr}`}
                </div>
                <div className="col-5">
                  {article.factChecked && (
                    <div className="fced d-flex openSans-Bold">
                      <div className="fcIcon">
                        <CustomIcon icon="factChecked" />
                      </div>
                      {factCheckedLabel}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-12 articleImage d-lg-block d-none">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  image={image}
                  alt={article.image?.alternativeText}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12 articleImage d-lg-none d-block">
              <GatsbyImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                image={image}
                alt={article.image?.alternativeText}
              />
            </div>
          </div>

          <div className="row d-lg-none d-block">
            <div className="articleSummary ming text-center openSans-SemiBold col-12">
              {article.summary}
            </div>
          </div>
          <div className="row d-none d-lg-block ms-0">
            <div className="lastUpdate teal lora-Bold">
              {` ${lastUpdateLabel}: ${updateTimeStr}`}
            </div>
          </div>
          <div className="row d-lg-none d-block mobile-anchors mb-2">
            <div className="content-text openSans-Bold ming mb-2">
              {ContentsLabel}
            </div>
            {article.content.map((component, index) => {
              return (
                (component.strapi_component === "layout.title-richtext" ||
                  component.strapi_component === "covid.covid") && (
                  <div
                    key={index}
                    className="content-item d-flex flex-column ms-2"
                  >
                    <div className="d-flex flex-row row">
                      <div className="col-1 customIcon">
                        <CustomIcon className="icon" icon="rightArrow" />
                      </div>
                      <div className="col-11">
                        <a href={`#section_${component.title}`}>
                          {component.title}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              )
            })}

            {enableSideImageCta && (
              <div className={`sideImageCta my-4`}>
                <ImageCta cta={sideImageCta} />
              </div>
            )}
          </div>
        </div>

        <div className="container context ">
          <div className="row ">
            <div className="col-lg-8 col-12 article_content black-text pe-lg-4 ">
              {article.content.map((component, index) => {
                if (component.strapi_component === "layout.title-richtext") {
                  return (
                    <div
                      id={
                        component.htmlId
                          ? slugify(component.htmlId)
                          : slugify(component.title)
                      }
                      className="paragraph "
                      key={`content-${index}`}
                    >
                      <div className="row position-relative">
                        <span id={`section_${component.title}`}></span>
                        <div className="noPadLeft">
                          <CustomMarkdown
                            html={component.content}
                            toSanitize={false}
                          ></CustomMarkdown>
                        </div>
                      </div>
                    </div>
                  )
                } else if (component.strapi_component === "covid.covid") {
                  return (
                    <div className="paragraph " key={`content-${index}`}>
                      <div className="row " id={`section_${component.title}`}>
                        <Covid article={component} lang={lang} />
                      </div>
                    </div>
                  )
                } else if (component.strapi_component === "layout.cta") {
                  return (
                    <div className="paragraph " key={`content-${index}`}>
                      <div className="row " id={`section_${component.title}`}>
                        <div
                          className="bannerBackground soft-corners"
                          style={{
                            backgroundImage: `url('${getExternalImg(
                              component.background
                            )}')`,
                          }}
                        >
                          <div className="content">
                            <div className="title">
                              <h2>{component.title}</h2>
                            </div>
                            {component.cta && (
                              <Button
                                lang={lang}
                                type={component.cta.template}
                                text={component.cta.buttonLabel}
                                url={component.cta.url}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else if (component.strapi_component === faqCompLabel) {
                  return (
                    <div
                      className={`faqComp`}
                      key={`content-${index}`}
                      id={`section_${component.sectionTitle}`}
                    >
                      <FaqInPage
                        title={component.sectionTitle}
                        faq={component.questionAnswer}
                        meta={metaData}
                        setMeta={setMetaData}
                        titleColor="black-text"
                      />
                    </div>
                  )
                } else if (
                  component.strapi_component === contactFormLabel &&
                  component.enable
                ) {
                  return (
                    <div
                      className={`contactFormComp bg-ming soft-corners soft-shadows p-4 `}
                      key={`content-${index}`}
                      id={`section_${component.sectionTitle}`}
                    >
                      <ContactForm
                        aleaToken={token}
                        lang={lang}
                        articleTitle={article.title}
                        labels={contactFormLabels}
                        isHalf={false}
                        location={location}
                      />
                    </div>
                  )
                } else return <></>
              })}
              {article.footnote && enableFootnote && (
                <div className="paragraph footnote ">
                  <div className="row">
                    <div className="noPadLeft soft-corners soft-shadows">
                      <CustomMarkdown
                        html={article.footnote.content}
                      ></CustomMarkdown>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-4 side-bar ming ps-4 d-lg-block d-none">
              <div>
                <SocialIcon type="share" />
                {/* <CustomIcon /> */}
              </div>
              <div className="menu">
                <div className={"mb-4"}>
                  <div className="content-text mt-3">{ContentsLabel}</div>
                  {article.content.map((component, index) => {
                    return (
                      (component.strapi_component === "layout.title-richtext" ||
                        component.strapi_component === "covid.covid" ||
                        component.strapi_component === faqCompLabel) && (
                        <div
                          key={index}
                          className="content-item d-flex flex-column"
                        >
                          <div className="d-flex flex-row row">
                            <div className="col-1 customIcon">
                              <CustomIcon className="icon" icon="rightArrow" />
                            </div>
                            <div className="col-11">
                              <a
                                href={`#section_${
                                  component.title ?? component.sectionTitle
                                }`}
                              >
                                {component.title ?? component.sectionTitle}
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  })}
                </div>

                {enableSideForm && (
                  <div
                    className={`contactFormSidebar bg-ming soft-shadows  soft-corners py-4`}
                  >
                    <ContactForm
                      aleaToken={token}
                      lang={lang}
                      articleTitle={article.title}
                      labels={contactFormLabels}
                      isHalf={true}
                      location={location}
                      isColumn={true}
                    />
                  </div>
                )}

                {enableSideImageCta && (
                  <div className={`sideImageCta my-4`}>
                    <ImageCta cta={sideImageCta} />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row d-lg-none d-block">
            <div>
              <SocialIcon type="share" />
            </div>
          </div>
          {!moreArticlesError && (
            <>
              <div className="row featuredRow">
                <Featured
                  props={moreArticles}
                  labels={labels.find(l => l.locale === article.locale)}
                  lang={lang}
                />
              </div>
            </>
          )}
        </div>
        {/* </div> */}
      </Layout>
    </div>
  )
}
export default ArticleTemplate
