// extracted by mini-css-extract-plugin
export var alertRed = "ContactForm-module--alert-red--JmqxT";
export var bgBarGrey = "ContactForm-module--bg-bar-grey--8sJ0f";
export var bgBeige = "ContactForm-module--bg-beige--4JUQW";
export var bgCopper = "ContactForm-module--bg-copper--WbO5h";
export var bgDaffodil = "ContactForm-module--bg-daffodil--uD+AW";
export var bgGreyText = "ContactForm-module--bg-grey-text--FOVoZ";
export var bgMing = "ContactForm-module--bg-ming--wSDL9";
export var bgMint = "ContactForm-module--bg-mint--6IA2s";
export var bgRed = "ContactForm-module--bg-red--fW9FB";
export var bgRedTrans = "ContactForm-module--bg-red-trans--aJnVT";
export var bgSand = "ContactForm-module--bg-sand--5ObL+";
export var bgSoftSun = "ContactForm-module--bg-softSun--xrjm4";
export var bgTeal = "ContactForm-module--bg-teal--Jbwr+";
export var bgWhite = "ContactForm-module--bg-white--qFmP3";
export var bgWomenSharp = "ContactForm-module--bg-women-sharp--JKKdA";
export var blackText = "ContactForm-module--black-text--gbUEG";
export var contactForm = "ContactForm-module--contactForm--vXFqs";
export var copper = "ContactForm-module--copper--bkfuO";
export var corners = "ContactForm-module--corners--9s7ne";
export var cursorNormal = "ContactForm-module--cursor-normal--eFl9C";
export var cursorPointer = "ContactForm-module--cursor-pointer--qULC2";
export var dUnset = "ContactForm-module--d-unset--C-cc1";
export var darkGrey = "ContactForm-module--dark-grey--WTn5v";
export var dash = "ContactForm-module--dash--dXnxM";
export var flex = "ContactForm-module--flex--6bb7Q";
export var flex1 = "ContactForm-module--flex-1--uh-9X";
export var fontSize12 = "ContactForm-module--font-size-12--Z18Uc";
export var fontSize20 = "ContactForm-module--font-size-20--5-ycS";
export var formWrapper = "ContactForm-module--formWrapper--XY0LX";
export var greyPlaceholder = "ContactForm-module--grey-placeholder--J5ziC";
export var greyText = "ContactForm-module--grey-text--GwzvE";
export var h1sizing = "ContactForm-module--h1sizing--Bzgw7";
export var h2sizing = "ContactForm-module--h2sizing--j82cO";
export var hidden = "ContactForm-module--hidden--nQQxP";
export var imageAni = "ContactForm-module--imageAni--Ge5yS";
export var imageZoom = "ContactForm-module--imageZoom--mGgOa";
export var imageZoomGatsby = "ContactForm-module--imageZoomGatsby--NZ6bu";
export var italic = "ContactForm-module--italic--+KpNJ";
export var lightGrey = "ContactForm-module--light-grey--SvXps";
export var logo = "ContactForm-module--logo--t0j+j";
export var lora = "ContactForm-module--lora--DQbCY";
export var loraBold = "ContactForm-module--lora-Bold--UE2-F";
export var loraBoldItalic = "ContactForm-module--lora-BoldItalic--UUcy0";
export var loraMedium = "ContactForm-module--lora-Medium--BICqD";
export var loraSemiBold = "ContactForm-module--lora-SemiBold--Vbuj8";
export var main = "ContactForm-module--main--e+bZJ";
export var ming = "ContactForm-module--ming--3etyL";
export var mingHover2 = "ContactForm-module--ming-hover-2--yDqpF";
export var modalOpen = "ContactForm-module--modal-open--ZpDtd";
export var noUnderline = "ContactForm-module--no-underline--ie1Rs";
export var openSans = "ContactForm-module--openSans--XnYQA";
export var openSansBold = "ContactForm-module--openSans-Bold--81Uqp";
export var openSansSemiBold = "ContactForm-module--openSans-SemiBold--XqEPt";
export var quotation = "ContactForm-module--quotation--5d5ye";
export var round = "ContactForm-module--round--Rsfzk";
export var roundCorners = "ContactForm-module--round-corners--5Ph4k";
export var softCorners = "ContactForm-module--soft-corners--kEu-c";
export var softShadows = "ContactForm-module--soft-shadows--5NVJn";
export var softerCorners = "ContactForm-module--softer-corners--21t2b";
export var softyCorners = "ContactForm-module--softy-corners--taunS";
export var tableShadow = "ContactForm-module--table-shadow--SEgGb";
export var teal = "ContactForm-module--teal--sbKK2";
export var thanksMsg = "ContactForm-module--thanksMsg--j0Zof";
export var topNavShadow = "ContactForm-module--top-nav-shadow--lUOay";
export var uppercase = "ContactForm-module--uppercase--6dury";
export var wFull = "ContactForm-module--w-full--QP461";
export var white = "ContactForm-module--white--yEJHg";
export var womenRed = "ContactForm-module--women-red--VDVm9";
export var womenSharp = "ContactForm-module--women-sharp--nJrzi";