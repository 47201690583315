import * as style from "./ContactForm.module.scss"
import React, { useState } from "react"
import QuotationForm from "./quotation/Form"
import Button from "./Button"
import { formValidation } from "../plugins/common"
import { pipedriveValue } from "../enumerator/pipedriveValue"
import { createPipedrivePersonLead } from "../DAO/eqtDAO"
import axios from "../plugins/axios"
import Loading from "./Loading"

const ContactForm = ({
  addtionalClass,
  aleaToken,
  articleTitle,
  lang,
  labels,
  isHalf,
  // location,
  // isColumn,
}) => {
  const { invalidFormMsg, title, thanksMessage, submitLabel } = labels

  const defaultForm = {
    firstName: "",
    familyName: "",
    phone: "",
    email: "",
    countryCode: "+852",
  }

  const defaultFormError = {
    firstName: false,
    familyName: false,
    phone: false,
    email: false,
  }

  const [form, setForm] = useState(defaultForm)
  const [formError, setFormError] = useState(defaultFormError)
  const [errorMessage, setErrorMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    if (!formValidation(form, setFormError, setErrorMessage, invalidFormMsg)) {
      return
    }
    savePerson()
  }

  const savePerson = async () => {
    setLoading(true)
    // consts form

    const pipedriveEntry = {
      name: form.firstName + " " + form.familyName,
      phone: form.countryCode + form.phone,
      email: form.email,
      source: pipedriveValue.ARTICLE_FORM,
      // url: location.href,
      // source: pipedriveValue.SOURCE_ALEA,
      // formPosition: isColumn
      //   ? pipedriveValue.POSITION_COLUMN
      //   : pipedriveValue.POSITION_CONTENT,
      note: articleTitle,
      preferedLanguage: lang === "zh-Hant" ? 162 : lang === "fr" ? 161 : 160,
    }

    try {
      const pipedriveRes = await createPipedrivePersonLead(
        aleaToken,
        pipedriveEntry
      )

      // //log
      const log = {
        title: form.firstName + " " + form.familyName + " from " + articleTitle,
        lang: lang,
        raw: pipedriveEntry,
      }
      await axios
        .strapi(aleaToken)
        .post("/get-a-quote-submissions", log)
        .catch(err => {
          throw err
        })

      if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
          event: {
            hitType: "event",
            eventCategory: "form submission",
            eventAction: "lead",
          },
        })
      }

      setSubmitted(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      className={`${style.contactForm} ${
        addtionalClass ? style[addtionalClass] : ""
      }`}
    >
      {loading ? (
        <div className={`d-relative`}>
          <Loading />
        </div>
      ) : (
        ""
      )}
      <h3 className={`text-center white`}>{title}</h3>
      <div className={`text-center white`}>{errorMessage}</div>
      <div className={` ${style.formWrapper}`}>
        {submitted ? (
          <div className={`white text-center confirmation ${style.thanksMsg}`}>
            {thanksMessage}
          </div>
        ) : (
          <div>
            <QuotationForm
              form={form}
              labels={labels}
              errors={formError}
              isHalf={isHalf}
              isVertical={true}
              extraClass={"compact"}
              allWhite={true}
            />
            <div className={`text-center mt-4`}>
              <Button
                id="article_lead_form_submit"
                type="whiteBtn"
                text={submitLabel}
                event={submit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactForm
